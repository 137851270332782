.ant-layout-content {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	min-height: 100%;
}

.ant-upload.ant-upload-select-picture-card {
	border-width: 1px;
	height: 104px;
	width: 104px;
}

.loading.cover-content {
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.loading.cover-page {
	position: fixed;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.loading.cover-scroll {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	overflow-y: auto;
}

.loading .ant-spin {
	color: #062d10;
}

.label {
	margin-bottom: 6px;
}

.label label {
	font-weight: 600;
}
